import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import StudentLogin from "../Student/Auth/Login/login";
import StudentResetPassword from "../Student/Auth/Reset-Password/student-reset-password";
import StudentLayout from "../Components/StudentLayout/student-layout";
import Dashboard from "../Student/Pages/Dashboard/dashboard";
import TellAboutCarrer from "../Student/Pages/TellAboutCareer/tellabout-career";
import StudentProfile from "../Student/Pages/StudentProfile/student-profile";
import MeetingsEvents from "../Student/Pages/MeetingsEvents/meetings-events";
import FindOut from "../Student/Pages/FindOut/find-out";
import CheckScore from "../Student/Pages/CheckScore/check-score";
import CourseFee from "../Student/Pages/CourseFee/course-fee";
import FeeCalculator from "../Student/Pages/FeeCalculator/fee-calculator";
import LifeSkills from "../Student/Pages/LifeSkills/life-skills";
import Communication from "../Student/Pages/Communication/communication";
import CollegesList from "../Components/CollegesList/colleges-list";
import CommunicationQuiz from "../Student/Pages/Communication-Quiz/communication-quiz";
import CounsellerLogin from "../Counseller/Auth/Login/login";
import UserResetPassword from "../Counseller/Auth/Reset-Password/user-reset-password";
import CounsellerLayout from "../Components/CounsellerLayout/layout";
import Home from "../Counseller/Pages/Home/home";
import Scholarship from "../Counseller/Pages/Scholarship/scholarship";
import UserStudentProfile from "../Counseller/Pages/UserProfile/user-profile";
import StudentList from "../Counseller/Pages/StudentList/student-list";
import PlatFormSetup from "../Counseller/Pages/CounsellerProfile/PlatformSetup/platform-setup";
import StudentOnboarding from "../Counseller/Pages/CounsellerProfile/StudentOnboarding/student-onboarding";
import EngageStudents from "../Counseller/Pages/CounsellerProfile/EngageStudents/engage-students";
import AccessResources from "../Counseller/Pages/CounsellerProfile/AccessResources/access-resources";
import PersonalInfo from "../Counseller/Pages/Personalinfo/personal-info";
import EmailSettings from "../Counseller/Pages/Emailsettings/email-settings";
import EditBasicInfo from "../Counseller/Pages/Personalinfo/Editbasicinfo/edit-basicInfo";
import EditAdditionalInfo from "../Counseller/Pages/Personalinfo/Editadditionalinfo/edit-additional-info";
import EditPreferences from "../Counseller/Pages/Personalinfo/Editpreferences/edit-preferences";
import CareerPreferences from "../Counseller/Pages/Careerpreferences/career-preferences";
import ProductCheckout from "../Counseller/Pages/ProductCheckout/product-checkout";
import ProductInventory from "../Counseller/Pages/Products-Inventory/products-inventory";
import InventoryManage from "../Counseller/Pages/InventoryManage/inventory-manage";
import ExploreColleges from "../Counseller/Pages/ExploreColleges/explore-colleges";
import ChooseCareer from "../Counseller/Pages/ChooseCareer/choose-career";
import TestDeadlines from "../Counseller/Pages/TestDeadlines/testdead-lines";
import Products from "../Counseller/Pages/Products/products";
import MyDashboard from "../Counseller/Pages/MyDashBoard/my-dashboard";
import { useAuth } from "../Contexts/auth-context";
import ProductDetails from "../Counseller/Pages/Product-Details/product-details";
import TestDetails from "../Student/Pages/Test-details/test-details";
import CareerTrend from "../Counseller/Pages/CareerTrends/user-career-trends";
import CareerTranding from "../Student/Pages/Career-trending/student-career-trending";
import CareerTrands from "../Components/Career-trends/career-trends";
import UserSuccess from "../Counseller/Pages/User-success/user-success";
import UserPanding from "../Counseller/Pages/User-pending/user-pending";
import UserFailed from "../Counseller/Pages/UserFailed/user-failed";
import ScholarshipSearch from "../Components/ScholarshipSearch/scholarship-search";
import EntranceExam from "../Student/Pages/Entrance-exam/entrance-exam";
import CollegeList from "../Counseller/Pages/college-list/college-list";
import { getCompanyByAlias } from "../services/util/util-service";
import CareerList from "../Student/Pages/career/careerList";
import CareerDetails from "../Student/Pages/career/careerDetails";

const AllRoutes = () => {
  const { user } = useAuth();
  const [companyAlias, setCompanyAlias] = useState({});

  const fetchCompanyByAlias = async (alias) => {
    await getCompanyByAlias(alias).then((res) => {
      if (res && res?.data?.data) {
        setCompanyAlias(res?.data?.data);
        localStorage.setItem("companyDetails", JSON.stringify(res?.data?.data));
      }
    });
  };

  useEffect(() => {
    fetchCompanyByAlias(window.location.hostname);
  }, []);

  console.log({companyAlias})
  return (
    <React.Fragment>
      <BrowserRouter>
        <React.Suspense>
          <Routes>
            <>
              <Route
                path={`student-login`}
                element={
                  user ? <Navigate to="/student/dashboard" /> : <StudentLogin companyAlias = {companyAlias}/>
                }
              ></Route>
              <Route
                path={`/student-reset-password`}
                element={<StudentResetPassword />}
              ></Route>
              <Route
                path={`/`}
                element={
                  user ? <Navigate to="/student/dashboard" /> : <StudentLogin companyAlias = {companyAlias}/>
                }
              ></Route>
            </>

            <Route path={`  `} element={<StudentLogin companyAlias = {companyAlias} />}></Route>
            <Route
              path={`/student-reset-password`}
              element={<StudentResetPassword />}
            ></Route>

            <Route path={`/`} element={<StudentLogin companyAlias = {companyAlias}/>}></Route>
            <Route path={`/student`} element={<StudentLayout />}>
              <Route path={`dashboard`} element={<Dashboard />} />
              <Route path={`about-career`} element={<TellAboutCarrer />} />
              <Route path={`profile`} element={<StudentProfile />} />
              <Route path={`meetings`} element={<MeetingsEvents />} />
              <Route path={`findout-now`} element={<FindOut />} />
              <Route path={`checkscore`} element={<CheckScore />} />
              <Route path={`career-trends`} element={<CareerTranding />} />
              <Route path={`career-list`} element={<CareerList />} />
              <Route path="career/:title/:id" element={<CareerDetails />} />

              <Route
                path={`scholarship-search`}
                element={<ScholarshipSearch />}
              />
              <Route path={`course-fee`} element={<CourseFee />} />
              <Route path={`fee-calculator`} element={<FeeCalculator />} />
              <Route path={`life-skills`} element={<LifeSkills />} />
              <Route path={`entrance-exam`} element={<EntranceExam />} />
              <Route path={`skill-detail/:id`} element={<Communication />} />
              <Route path={`college-list`} element={<CollegesList />} />
              <Route path={`test-details/:id`} element={<TestDetails />} />

              <Route path={`skill-quiz/:id`} element={<CommunicationQuiz />} />
            </Route>
            <Route path={`user-login`} element={<CounsellerLogin />} />
            <Route
              path={`/user-reset-password`}
              element={<UserResetPassword />}
            ></Route>

            <Route path={`/user`} element={<CounsellerLayout />}>
              <Route path={`home`} element={<Home />} />
              <Route path={`profile/:id`} element={<UserStudentProfile />} />

              <Route path={`student-list`} element={<StudentList />} />

              <Route path={`platform-setup`} element={<PlatFormSetup />} />
              <Route
                path={`student-onboarding`}
                element={<StudentOnboarding />}
              />
              <Route path={`career-trends`} element={<CareerTrend />} />
              <Route path={`course-fee`} element={<CourseFee />} />
              <Route path={`fee-calculator`} element={<FeeCalculator />} />
              <Route path={`meetings`} element={<MeetingsEvents />} />
              <Route path={`engage-students`} element={<EngageStudents />} />
              <Route path={`access-resources`} element={<AccessResources />} />
              <Route path={`success`} element={<UserSuccess />} />
              <Route path={`pending`} element={<UserPanding />} />
              <Route path={`personal-info`} element={<PersonalInfo />} />
              <Route path={`email-settings`} element={<EmailSettings />} />
              <Route path={`edit-basicinfo`} element={<EditBasicInfo />} />
              <Route path={`failed`} element={<UserFailed />} />
              <Route path={`career-list`} element={<CareerList />} />
              <Route path="career/:title/:id" element={<CareerDetails />} />
              
              <Route
                path={`edit-additionalinfo`}
                element={<EditAdditionalInfo />}
              />
              <Route
                path={`edit-preferencesinfo`}
                element={<EditPreferences />}
              />
              <Route
                path={`career-preferences`}
                element={<CareerPreferences />}
              />
              <Route
                path={`product-checkout/:id`}
                element={<ProductCheckout />}
              />
              <Route path={`inventory`} element={<ProductInventory />} />
              <Route
                path={`inventory-manage/:id`}
                element={<InventoryManage />}
              />
              <Route path={`career-trends`} element={<CareerTrands />} />
              <Route path={`college-list`} element={<CollegeList />} />
              <Route path={`explore-college`} element={<ExploreColleges />} />
              <Route path={`choose-career`} element={<ChooseCareer />} />
              <Route path={`entrance-exam`} element={<TestDeadlines />} />
              <Route path={`scholarship-search`} element={<Scholarship />} />
              <Route path={`products`} element={<Products />} />
              <Route
                path={`product-details/:id`}
                element={<ProductDetails />}
              />
              <Route path={`dashboard`} element={<MyDashboard />} />
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default AllRoutes;
