import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CareerFamousPersonality = () => {
    const [personalities, setPersonalities] = useState([]);
    const [loading, setLoading] = useState(true); // Add a loading state
    const [error, setError] = useState(null); // Add an error state
    const { id } = useParams();

    const fallbackImage = `${window.location.origin}/assets/images/yellow-student-boy.svg`;

    useEffect(() => {
        // Fetch famous personalities data from the API
        const fetchPersonalities = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}career/fetchFamousPersonality`, {
                    career_id: id
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                });

                // Check if the API returns data correctly
                console.log("API response: ", response.data); // Debug log

                // Handle null data case
                if (response.data.success && response.data.data) {
                    setPersonalities(response.data.data);  // Set data if success is true and data is not null
                } else {
                    setPersonalities([]); // Set empty array if no data or null
                }
            } catch (error) {
                console.error("Error fetching personalities:", error);
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchPersonalities();
    }, [id]);

    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 2 },
            700: { items: 3 },
            1000: { items: 4 },
        },
    };

    // Debug log for state values
    console.log("Loading: ", loading);
    console.log("Error: ", error);
    console.log("Personalities: ", personalities);

    // Early return if there is no data
    if (!loading && !error && personalities.length === 0) {
        console.log("No data, returning null");
        return null; // Hide the entire section if there's no data
    }

    return (
        <section className="container-fluid py-5 bg-skyblue position-relative z-0" id="famousPersonalities">
            <div className="max-1140 mx-auto">
                <h1 className="font-32 font-900 mb-3 text-white">Famous Personalities</h1>

                {loading && <p className="text-white">Loading...</p>} {/* Loading state */}
                {error && <p className="text-white">{error}</p>} {/* Error state */}

                {!loading && !error && personalities.length > 0 && (
                    <OwlCarousel id='FamousPersonalities' className="owl-carousel fourslider" {...options}>
                        {personalities.map((personality) => (
                            <div className="item" key={personality.id}>
                                <div className="guiding_alumni_block">
                                    <div className="guiding_alumni_top">
                                        <div className="guiding_alumni_profile_img">
                                            <img 
                                                src={personality.profile_picture || fallbackImage} 
                                                onError={(e) => e.target.src = fallbackImage} 
                                                width="80" 
                                                alt={personality.name} 
                                            />
                                        </div>
                                        <div className="guiding_alumni_profile_name">
                                            {/* Name as a hyperlink */}
                                            <a href={personality.wikipedia_url} target="_blank" rel="noopener noreferrer">
                                                {personality.name}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="guiding_alumni_college_cource">
                                        <ul>
                                            <li><span className="font-500">Famous For:</span> {personality.famous_for}</li>
                                            {personality.awards && personality.awards.length > 0 && (
                                                <li><span className="font-500">Awards:</span> {personality.awards}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                )}
            </div>
        </section>
    );
};

export default CareerFamousPersonality;
