import React, { useState, useEffect, useRef } from 'react';
import CareerDetailsAbout from './careerDetailsAbout';
import CareerTopCollege from './careerTopCollege';
import CareerProspects from './careerProspects';
import CareerProsCons from './careerProsCons';
import CareerFamousPersonality from './careerFamousPersonality';
import { useParams } from 'react-router-dom';

const CareerDetails = () => {
  const { id } = useParams();
  const [careerData, setCareerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState();
  const [tabStatuses, setTabStatuses] = useState({
    topColleges: false,
    careerProspects: false,
    prosCons: false,
    famousPersonalities: false,
  });

  const aboutRef = useRef(null);
  const topCollegesRef = useRef(null);
  const careerProspectsRef = useRef(null);
  const prosConsRef = useRef(null);
  const famousPersonalitiesRef = useRef(null);

  useEffect(() => {
    fetchCareerData(id);
    fetchCareerTabs(id);
  }, [id]);

  const fetchCareerData = async (career_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}career/fetchBasicInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ career_id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.success) {
        setCareerData(result.data[0]);
      } else {
        setError('Failed to fetch career data');
      }
    } catch (error) {
      setError('An error occurred while fetching the data.');
      console.error('Fetch error:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCareerTabs = async (career_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}career/showCareerTabs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ career_id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.success) {
        const { top_college_status, prospects_status, proscons_status, personalities_status } = result.data;
        setTabStatuses({
          about: 1,
          topColleges: top_college_status === 1,
          careerProspects: prospects_status === 1,
          prosCons: proscons_status === 1,
          famousPersonalities: personalities_status === 1,
        });
      } else {
        setError('Failed to fetch tab statuses');
      }
    } catch (error) {
      setError('An error occurred while fetching tab statuses.');
      console.error('Fetch error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const aboutOffset = aboutRef.current.getBoundingClientRect().top;
      const prosConsOffset = prosConsRef.current.getBoundingClientRect().top;

      if (aboutOffset >= 0 && aboutOffset < window.innerHeight) {
        setActiveTab('about');
      } else if (prosConsOffset >= 0 && prosConsOffset < window.innerHeight) {
        setActiveTab('prosCons');
      }
    };

    // Set active tab to 'about' on load
    const timeoutId = setTimeout(() => {
      setActiveTab('about');
    }, 500); // Adjust the timeout duration as needed

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on component unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleTabChange = (tabName, ref) => {
    setActiveTab(tabName);
    if (ref) {
      const yOffset = -100; // Optional: Adjust this value
      const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  
  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === aboutRef.current) {
            setActiveTab('about');
          } else if (entry.target === topCollegesRef.current) {
            setActiveTab('topColleges');
          } else if (entry.target === careerProspectsRef.current) {
            setActiveTab('careerProspects');
          } else if (entry.target === prosConsRef.current) {
            setActiveTab('prosCons');
          } else if (entry.target === famousPersonalitiesRef.current) {
            setActiveTab('famousPersonalities');
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    if (aboutRef.current) observer.observe(aboutRef.current);
    if (topCollegesRef.current && tabStatuses.topColleges) observer.observe(topCollegesRef.current);
    if (careerProspectsRef.current && tabStatuses.careerProspects) observer.observe(careerProspectsRef.current);
    if (prosConsRef.current && tabStatuses.prosCons) observer.observe(prosConsRef.current);
    if (famousPersonalitiesRef.current && tabStatuses.famousPersonalities) observer.observe(famousPersonalitiesRef.current);

    return () => {
      observer.disconnect(); // Clean up the observer on component unmount
    };
  }, [tabStatuses]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div className="main sideBarEnable flex-1">
        <div className="flex-1 d-flex flex-column">
          <div className="flex-1">
            <div className="max-1140 mx-auto">
              <div className="row g-2 pt-5 pb-3">
                <div className="col">
                  <h3>{careerData ? careerData.name : 'Loading...'}</h3>
                </div>
              </div>
            </div>

            <div className="max-1140 mx-auto position-sticky top-0 z1">
              <div className="job_internship_tab polartabsec">
                <ul className="nav nav-tabs">
                  {tabStatuses.about && (
                    <li className="nav-item">
                      <a
                        className={`nav-link ${activeTab === 'about' ? 'active' : ''}`}
                        onClick={() => handleTabChange('about', aboutRef)}
                      >
                        About
                      </a>
                    </li>
                  )}
                  {tabStatuses.topColleges && (
                    <li className="nav-item">
                      <a
                        className={`nav-link ${activeTab === 'topColleges' ? 'active' : ''}`}
                        onClick={() => handleTabChange('topColleges', topCollegesRef)}
                      >
                        Top Colleges
                      </a>
                    </li>
                  )}
                  {tabStatuses.careerProspects && (
                    <li className="nav-item">
                      <a
                        className={`nav-link ${activeTab === 'careerProspects' ? 'active' : ''}`}
                        onClick={() => handleTabChange('careerProspects', careerProspectsRef)}
                      >
                        Career Prospects
                      </a>
                    </li>
                  )}
                  {tabStatuses.prosCons && (
                    <li className="nav-item">
                      <a
                        className={`nav-link ${activeTab === 'prosCons' ? 'active' : ''}`}
                        onClick={() => handleTabChange('prosCons', prosConsRef)}
                      >
                        Pros and Cons
                      </a>
                    </li>
                  )}
                  {tabStatuses.famousPersonalities && (
                    <li className="nav-item">
                      <a
                        className={`nav-link ${activeTab === 'famousPersonalities' ? 'active' : ''}`}
                        onClick={() => handleTabChange('famousPersonalities', famousPersonalitiesRef)}
                      >
                        Famous Personalities
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div ref={aboutRef}>
              <CareerDetailsAbout />
            </div>
            <div ref={topCollegesRef}>
              <CareerTopCollege />
            </div>
            <div ref={careerProspectsRef}>
              <CareerProspects />
            </div>
            <div ref={prosConsRef}>
              <CareerProsCons />
            </div>
            <div ref={famousPersonalitiesRef}>
              <CareerFamousPersonality />
            </div>
          </div>

          <div className="footer max-1140 mx-auto">
            <div className="row g-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerDetails;
